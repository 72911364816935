import './index.scss';
import create_hidden_input from "../../module/create_hidden_input/create_hidden_input";



$('.slider__block').slick({
    infinite: true,
    slidesToShow: 1.6,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    dots: true,
    arrows: false,
    focusOnSelect: true,
    pauseOnHover: false,
    lazyLoad: "ondemand",
    responsive: [
        {
            breakpoint: 1120,
            settings: {
                slidesToShow: 1,
                centerPadding: '150px',
            }
        },
        {
            breakpoint: 625,
            settings: {
                slidesToShow: 1,
                centerPadding: '50px',
            }
        },]
});

$('.slider_arrow_next').click(function () {
    $(".slider__block").slick('slickNext');
});



