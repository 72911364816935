export default function create_hidden_input(value, name, wrapper) {
    $('.form_type').empty();
    let form__block = document.createElement('div');
    form__block.classList.add('form_type');

    let form_title = document.createElement('div');
    form_title.classList.add('form_title', 'standart_title', 'standart_title_2', 'color_blue');
    form_title.textContent = value;

    let hidden_input = document.createElement('input');
    hidden_input.classList.add('hidden_input');
    hidden_input.setAttribute(`type`, 'hidden');
    hidden_input.setAttribute(`name`, name);
    hidden_input.setAttribute(`value`, value);

    form__block.appendChild(form_title);
    form__block.appendChild(hidden_input);

    $(wrapper)[0].prepend(form__block);
};