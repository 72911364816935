import './index.scss';
import return_anim_item from "./../../module/animation";
let wait_time = 1000;

function create_cart(content) {
    let code;
    let current_price = 0;

    if (content.price == 0) {
        current_price = content.second_price;
    } else if (content.price != 0) {
        current_price = content.price;
    }

    if (content.code != null) {
        code = ` 
        <span class="header_content_item_type sub_title sub_title_0 color_gray_0">
            ${content.code}
        </span>`
    } else {
        code = ``
    }
    let header_content_item_profile = document.createElement('a');
    header_content_item_profile.setAttribute(`href`, content.url);
    header_content_item_profile.classList.add('header_content_item_profile');
    header_content_item_profile.innerHTML = `
        <span class="header_content_item_img">
            <img src="${content.image}" alt="item">
        </span>
        <span class="header_content_item">
            <span class="header_content_item_title sub_title sub_title_1 color_black">
                ${content.title}
            </span>
            ${code}
            <span class="header_content_item_price standart_title standart_title_1 color_black">
                ${current_price}${content.currency.symbol}
            </span>
        </span>
    `;

    return header_content_item_profile;
}

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
// function saveInput(value) {
//     console.log('value: ', value);
//     let test_json = [
//         {
//             img: '/static/source/img/catalog/catalog-3.png',
//             name: 'Манжета 65х90х10 редуктора при...',
//             type: 'DE18618.P',
//             price: '222,60грн',
//             href: '#'
//         },
//         {
//             img: '/static/source/img/catalog/catalog-3.png',
//             name: 'Манжета 65х90х10 редуктора при...',
//             type: 'DE18618.P',
//             price: '222,60грн',
//             href: '#'
//         },
//         {
//             img: '/static/source/img/catalog/catalog-3.png',
//             name: 'Манжета 65х90х10 редуктора при...',
//             type: 'DE18618.P',
//             price: '222,60грн',
//             href: '#'
//         }
//     ]



//     $(".header_content_item__block").empty();

//     if (test_json.length >= 1 && value.length != 0) {
//         $('.header_content_search').addClass('show');
//         fetch(`/api/search_items/?search_query=${value}`, {
//             method: 'GET',
//         })
//             .then(data => {
//                 return data.json();
//             })
//             .then(data => {
//                 if (data.length == 0) {
//                     let header_content_item_error = document.createElement('div');
//                     header_content_item_error.textContent = $('.header_content_item__block').attr('data-error');
//                     header_content_item_error.classList.add('header_content_item_error', 'sub_title', 'sub_title_1', 'color_black_1');
//                     $('.header_content_item__block')[0].appendChild(header_content_item_error);
//                 } else {
//                     $.each(data, function (index, value) {
//                         $('.header_content_item__block')[0].appendChild(create_cart(value));
//                     });
//                 }
//             });


//     } else {
//         $('.header_content_search').removeClass('show');
//     }
// }
// const processChange = debounce((value) => saveInput(value));

// $('.main_search').on('submit', function (e) {
//     e.preventDefault();
// });

// $('.main_search').on('change keydown paste input', function (e) {
//     processChange($(this).val());
// });




$('.lang_active').on('click', function () {
    $('.lang__block').toggleClass('active');
});
$(document).mouseup(function (e) { // событие клика по веб-документу
    let div = $(".lang__block"); // тут указываем ID элемента
    if (!div.is(e.target) // если клик был не по нашему блоку
        && div.has(e.target).length === 0) { // и не по его дочерним элементам
        $('.lang__block').removeClass('active');
    }
});
function create_header_animation() {
    return_anim_item('.header_info__block', 'anim_top', 0);
    return_anim_item('.header_content__block', 'anim_left', 0);

    return_anim_item('.header_info_time__block', 'anim_top', 100);
    return_anim_item('.header_info_address', 'anim_top', 200);
    return_anim_item('.header_info_contacts__block', 'anim_top', 300);

    return_anim_item('.header_content_logo', 'anim_bottom', 100);
    return_anim_item('.header_content_nav', 'anim_bottom', 200);
    return_anim_item('.header_content_search', 'anim_bottom', 300);
    return_anim_item('.header_content_svg', 'anim_bottom', 380);
    return_anim_item('.lang_active', 'anim_bottom', 420);

}

create_header_animation();


$('.modal_registery').on('click', function () {
    $.fancybox.close();
    $.fancybox.open({
        src: '#form_registery',
        touch: false
    });
});


$('.modal_login').on('click', function () {
    $.fancybox.close();
    $.fancybox.open({
        src: '#form_login',
        touch: false
    });
});




var burger = $('.menu-trigger');

burger.on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('.header').toggleClass('active');


    if ($(this).hasClass("active")) {
        $("html,body").css("overflow", "hidden");
    } else {
        $("html,body").css("overflow", "visible");
    }
})



$('.header_mobile_search').on('click', function () {
    $('.header_content_search').toggleClass('active');
});


document.querySelector('#search-btn').addEventListener('click', (e) => {
    e.preventDefault()
    const search_value = document.querySelector('.main_search').value
    if (search_value) {
        window.location = `/search?search=${search_value}`
    }
})

