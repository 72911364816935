import './star_rate.scss'
import './index.scss'
import { change_counter } from '../shop_scripts';
import { addToCartGT } from '../googleTagEvents/googleTagEvents';


$('.catalog_items_profile_btn, .purchase_btn').on('click', function () {
    $.fancybox.open({
        src: '#form_purchase',
        touch: false
    });
    $('.hidden_input').remove();
    let product_id = $(this).attr('data-product_id');
    let hidden_input = document.createElement('input');
    hidden_input.classList.add('hidden_input');
    hidden_input.setAttribute(`type`, 'hidden');
    hidden_input.setAttribute(`name`, 'product_id');
    hidden_input.setAttribute(`value`, product_id);
    $('.form_purchase')[0].prepend(hidden_input);
});


// if ($('.best_catalog__block').length >= 1) {
//     $('.best_catalog__block').slick({
//         infinite: true,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         autoplay: true,
//         dots: false,
//         arrows: false,
//         pauseOnHover: false,
//         lazyLoad: "ondemand",
//         // responsive: [
//         //     {
//         //         breakpoint: 1710,
//         //         settings: {
//         //             centerPadding: '250px',
//         //         }
//         //     },]
//     });
// }

$('.item_description_btn').on('click', function () {
    let id = Number($(this).attr('data-id-name'));
    let name = $(this).attr('data-name')
    let price = $(this).attr('data-price')

    let body = {
        "item_id": id,
    }


    fetch('/api/cart_items/', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            change_counter(data.cart_items_quantity, null);
            addToCartGT({id, name, price})
        });
})


if (document.querySelector('.catalog_items__block')) {
    document.querySelector('.catalog_items__block').addEventListener('contextmenu', (e) => {
        e.preventDefault()
    })
}
