// script interface
import '../components/common_componentc/normalize/index'
import '../components/interface/grid/index'
import '../components/interface/form/index'
import '../components/interface/button/index'


// script common elements
import '../components/module/form_errors/index'
import '../components/module/catalog_items/index'
import '../components/module/contact_section/index'
// import '../components/common_componentc/admin_panel/index'

// script pages
import '../components/common_componentc/header/index'
import '../components/common_componentc/footer/index'

import '../components/pages/index/index'


