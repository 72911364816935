import './index.scss'


function set_height_for_map() {
    setTimeout(() => {
        let padding = 30;
        let height_info = $('.contact_info').height();
        let current_height = height_info + padding;
        console.log('current_height: ', current_height);

        $('.contact_info_map').css('height', `calc(100% - ${current_height}px)`);
    }, 0);
}
set_height_for_map();